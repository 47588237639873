@use "@angular/material" as mat;
@import "@angular/material/theming";

// Available color palettes: https://material.io/resources/color
$app-primary: mat.define-palette(mat.$light-blue-palette, 300, 200, 600);
$app-accent: mat.define-palette(mat.$purple-palette, 400, 300, 700);
$app-warn: mat.define-palette(mat.$red-palette);

$app-secondary: mat.define-palette(mat.$light-green-palette, A700, 400, 700);
$app-bg-base: mat.define-palette(mat.$cyan-palette);

// Colors
$primary: mat.get-color-from-palette($app-primary);
$accent: mat.get-color-from-palette($app-accent);
$warn: mat.get-color-from-palette($app-warn);

$secondary: mat.get-color-from-palette($app-secondary);

$bg-base: mat.get-color-from-palette($app-bg-base);
$bg-light: mat.get-color-from-palette(mat.$grey-palette, 50);
$bg-light-blue: lighten($primary, 20%);
$bg-dark: #205c87;

$black: #000;
$white: #fff;
$green: mat.get-color-from-palette(mat.$light-green-palette, 700);
$light-green: mat.get-color-from-palette(mat.$light-green-palette, 500);
$lighter-green: mat.get-color-from-palette(mat.$light-green-palette, 300);
$yellow: mat.get-color-from-palette(mat.$yellow-palette, 600);
$red: mat.get-color-from-palette(mat.$red-palette, 700);
$orange: mat.get-color-from-palette(mat.$orange-palette, 800);
$grey: mat.get-color-from-palette(mat.$grey-palette, 700);
$dark-grey: mat.get-color-from-palette(mat.$grey-palette, 900);
$light-grey: mat.get-color-from-palette(mat.$grey-palette, 300);
$steel-grey: #b8cad3;

$text-light: #e5e5e5;
$text-dark: $bg-dark;
$text-grey: $grey;
$text-light-grey: $light-grey;
$text-primary: $primary;
$text-warn: $warn;
$text-blue: $text-dark;
$text-green: $green;
$text-red: $red;
$text-orange: $orange;

// Other
$card-margin: 12px;
$top-menu-height: 64px;

// Calculator
$calculator-padding: 10px;
