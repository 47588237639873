// You can add global styles to this file, and also import other style files
@import "./styling/variables.scss";
@import "./styling/theme.scss";

html,
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow-x: auto;
}

body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#body [hidden] {
  display: none !important;
}

mat-sidenav-container {
  min-width: 840px;
}

* {
  box-sizing: border-box;
}

// FIXME: pretty hacky
router-outlet:not(.exam-router) + * {
  position: absolute;
  top: $top-menu-height;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: inherit;
}

.pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.full-width {
  width: 100%;
}

#body .mat-card-full {
  position: absolute;
  top: $card-margin;
  bottom: $card-margin;
  left: $card-margin;
  right: $card-margin;
}

.text-light {
  color: $text-light !important;
}

.text-dark {
  color: $text-dark !important;
}

.text-grey {
  color: $text-grey !important;
}

.text-primary {
  color: $primary !important;
}

.text-warn {
  color: $text-warn !important;
}

.text-blue {
  color: $text-blue !important;
}

.text-green {
  color: $text-green !important;
}

.text-red {
  color: $text-red !important;
}

.text-orange {
  color: $text-orange !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.bg-base {
  background-color: $bg-light-blue !important;
}

.bg-light {
  background-color: $bg-light !important;
}

.bg-dark {
  background-color: $bg-dark !important;
}

.bg-primary {
  background-color: $primary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-accent {
  background-color: $accent !important;
}

.bg-warn {
  background-color: $warn !important;
}

.bold {
  font-weight: bold;
}

.bg-image {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("./assets/images/bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // filter: blur(2px);
  opacity: 0.2;
}

.fill-green {
  fill: $green;
}

.fill-light-green {
  fill: $light-green;
}

.fill-lighter-green {
  fill: $lighter-green;
}

.fill-yellow {
  fill: $yellow;
}

.fill-red {
  fill: $red;
}

.fill-steel-grey {
  fill: $steel-grey;
}

.mat-dialog-container {
  min-width: 400px;
}

#body .option-empty {
  color: $text-light-grey;
}

.calculator-dialog mat-dialog-container {
  padding: $calculator-padding;
  padding-top: 0;
  min-width: initial;
  width: 260px;
}

.notepad-dialog mat-dialog-container {
  padding: $calculator-padding;
  padding-top: 0;
  min-width: initial;
  width: 260px;
}

// Scrollbar
::-webkit-scrollbar-track {
  background-color: $light-grey;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: $bg-dark;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid $white;
  border-top-color: $primary;
  animation: spinner 0.8s linear infinite;
  opacity: 0.5;
}
